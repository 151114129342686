import { type ChangeEvent, type ReactElement } from 'react';

import css from './SelectInput.module.scss';
import { useTranslation } from 'react-i18next';

export interface SelectOption {
  value: any;
  text: string;
}
interface SelectInputProps {
  value: string | undefined;
  options: SelectOption[];
  onChange: (value: string | undefined) => void;
}

function SelectInput({ value, options, onChange }: SelectInputProps): ReactElement {
  function handleChange(e: ChangeEvent<HTMLSelectElement>): void {
    if (e.target.value.startsWith('--') && e.target.value.endsWith('--')) {
      onChange(undefined);
    } else {
      onChange(e.target.value);
    }
  }
  const { t } = useTranslation('common');

  return (
    <select onChange={handleChange} value={value} className={css.selectInput}>
      <option>-- {t('selectValue')} --</option>
      {options.map(option => {
        return (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        );
      })}
    </select>
  );
}

export { SelectInput };
