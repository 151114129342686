import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { PostcoderSearchResult } from '../../../../../../types/common.types.ts';
import { CountriesEnum } from '../../../../../../types/countries.types.ts';
import type {
  EnedisMeteringPoint,
  FRCommercialContextualData,
} from '../../../../../../types/country-specific/france.types.ts';
import {
  FranceConsentSpecificConfiguration,
  HostedConsentModel,
} from '../../../../../../types/hosted-consent.types.ts';
import { DataFetching } from '../../../../../animations/DataFetching/DataFetching.tsx';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import CollapsibleNotice from '../../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import AddressForm from '../../../../../molecules/AddressForm/AddressForm.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { FlowSteps } from '../../../fr.commercial.specific.tsx';
import { SearchForPRM } from '../SearchForPRM/SearchForPRM.tsx';
import css from './PRMCollection.module.scss';

export enum ComponentSteps {
  DATA_COLLECTION = 'data_collection',
  PRM_COLLECTION = 'prm_collection',
}

interface ComponentProps {
  consent: HostedConsentModel<FRCommercialContextualData, FranceConsentSpecificConfiguration>;
  onNext: () => void;
}

function PRMCollection({ consent, onNext }: ComponentProps): ReactElement {
  const allowedKeypresses = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Enter',
    'Home',
    'End',
  ];
  const { ConsentStore } = useContext(AppContext);
  const [errors, setErrors] = useState<null | string | string[]>(null);
  const [selectedAddress, setSelectedAddress] = useState<PostcoderSearchResult>({
    organisation: '',
    summaryline: '',
    number: '',
    street: '',
    dependentlocality: '',
    posttown: '',
    county: '',
    postcode: '',
    country: '',
  });
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
  const [step, setStep] = useState(ComponentSteps.DATA_COLLECTION);
  const { t } = useTranslation('fr-flow');
  const [companyData, setCompanyData] = useState<{ business_name: string; siret: string }>({
    business_name: '',
    siret: '',
  });

  function handleInput(key: keyof FRCommercialContextualData) {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      setCompanyData({ ...companyData, [key]: value });
      consent.contextual_data = {
        ...consent.contextual_data,
        [key]: value,
      };
    };
  }

  async function saveSelectedAddress(): Promise<void> {
    try {
      const { data: apiResponse } = await API.updateConsent(consent.id, {
        contextual_data: {
          ...consent.contextual_data,
          meter_address: selectedAddress,
        },
      });
      ConsentStore.setConsent(apiResponse.data);
    } catch (e: any) {
      setErrors(e);
    }
  }

  async function handleNext(): Promise<void> {
    await saveSelectedAddress();
    setStep(ComponentSteps.PRM_COLLECTION);
  }

  async function handleUpdateMeterNumber(
    foundMeters: EnedisMeteringPoint[],
    selectedMeters: string[],
    isManual: boolean,
  ): Promise<void> {
    try {
      const res = await API.updateConsent(consent.id, {
        meter_numbers: selectedMeters,
        contextual_data: {
          ...consent.contextual_data,
          found_meter_numbers: foundMeters,
          manual_input: isManual,
        },
      });
      ConsentStore.setConsent(res.data.data);
      onNext();
    } catch (e: any) {
      setErrors(e);
    }
  }

  function renderStep(): ReactElement {
    switch (step) {
      case ComponentSteps.DATA_COLLECTION:
        return (
          <>
            <Flex
              className={css.formWrapper}
              gap={Gap.MD}
              flexDirection={FlexDirection.COLUMN}
              align={Align.STRETCH}>
              <Heading>{t('fr-flow:prmCollectionStep.heading')}</Heading>
              <Muted>{t('fr-flow:prmCollectionStep.subHeading')}</Muted>
              {errors != null && <ErrorNotice errors={errors} />}
              <div className={css.postcodeSearch}>
                <TextInput
                  label={t('common:businessName')}
                  type="text"
                  required
                  onChange={handleInput('business_name')}
                  value={companyData.business_name}
                />
                <TextInput
                  label={t('fr-flow:common.siret')}
                  required
                  validationSchema={z
                    .string()
                    .length(14, t('fr-flow:common.validation.siretLength'))
                    .regex(/\d{14}/, t('fr-flow:common.validation.siretFormat'))}
                  onKeyDown={e => {
                    if (
                      (!/[\d]+/.test(e.key) || companyData.siret.length >= 14) &&
                      !allowedKeypresses.includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onValidationError={error => {
                    setErrors(error);
                  }}
                  type="text"
                  onChange={handleInput('siret')}
                  value={companyData.siret}
                />
                <div>
                  <AddressForm
                    validationSchema={{
                      country: z.string().min(1, t('validation:general.address.required.country')),
                      postcode: z
                        .string()
                        .min(1, t('validation:general.address.required.postcode')),
                      street: z.string().min(1, t('validation:general.address.required.street')),
                      posttown: z.string().min(1, t('validation:general.address.required.city')),
                      county: z.string().min(1, t('validation:general.address.required.county')),
                    }}
                    fixedCountry={CountriesEnum.FR}
                    onChange={(newState: PostcoderSearchResult) => {
                      setSelectedAddress(newState);
                    }}
                    onValidationError={(error?: string) => {
                      setIsAddressValid(!error);
                      setErrors(error ?? null);
                    }}
                    initialAddress={selectedAddress}
                    overrides={{
                      postcode: {
                        onKeyDown: e => {
                          if (
                            (!/\d+/.test(e.key) || selectedAddress.postcode.length >= 5) &&
                            !allowedKeypresses.includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </div>
                <Button
                  fullWidth
                  onClick={handleNext}
                  disabled={
                    errors !== null ||
                    !isAddressValid ||
                    !consent.contextual_data?.business_name ||
                    !consent.contextual_data?.siret
                  }>
                  {t('fr-flow:prmCollectionStep.searchPRM')}
                </Button>
              </div>
              <CollapsibleNotice
                type="info"
                title={t('fr-flow:prmCollectionStep.whatIsPRMNotice.heading')}>
                <p>{t('fr-flow:prmCollectionStep.whatIsPRMNotice.description')}</p>
              </CollapsibleNotice>
            </Flex>
          </>
        );
      case ComponentSteps.PRM_COLLECTION:
        return selectedAddress ? (
          <SearchForPRM
            address={selectedAddress}
            onChangeAddress={() => {
              setStep(ComponentSteps.DATA_COLLECTION);
            }}
            onSelect={async (
              found: EnedisMeteringPoint[],
              selected: string[],
              isManual: boolean,
            ) => {
              await handleUpdateMeterNumber(found, selected, isManual);
            }}
          />
        ) : (
          <></>
        );

      default:
        return <DataFetching text={t('common:fetchingData')} />;
    }
  }

  return (
    <Card account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps steps={FlowSteps} current={FlowSteps[0]} />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export default PRMCollection;
