import { type PropsWithChildren, type ReactElement, useContext, useEffect, useState } from 'react';
import { useParams, useSearch } from 'wouter';
import Loading from '../../atoms/Loading/Loading.tsx';
import { observer } from 'mobx-react-lite';
import API from '../../../api/API.ts';
import NotFound from '../../pages/NotFound/NotFound.tsx';
import AppContext from '../../../store/AppContext.ts';
import ConsentAlreadyCompleted from '../../molecules/ConsentAlreadyCompleted/ConsentAlreadyCompleted.tsx';
import {
  ConsentEvidenceStatus,
  ConsentEvidenceType,
  HostedConsentModel,
} from '../../../types/hosted-consent.types.ts';
import { Expired } from '../../pages/Expired/Expired.tsx';

function ConsentFlowGuard({ children }: PropsWithChildren): ReactElement | null {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [expired, setExpired] = useState(false);

  const { id } = useParams();
  const search = useSearch();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('hct');

  const { ConsentStore } = useContext(AppContext);
  const consent = ConsentStore.consent as HostedConsentModel<any, never>;

  useEffect(() => {
    const getConsent = async (token: string): Promise<void> => {
      try {
        API.setAuthHeader(token);
        const response = await API.axios.get<HostedConsentModel>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${id}`,
        );
        if (response == null) {
          throw Error('No data');
        }
        ConsentStore.setConsent(response.data);
        setLoading(false);
        setNotFound(false);
        setExpired(false);
      } catch (e: any) {
        if (e.response?.status === 401) {
          setExpired(true);
        } else {
          setNotFound(true);
        }
        setLoading(false);
      }
    };
    if (id != null && token != null) {
      getConsent(token)
        .then(() => {
          // reset the consent store to avoid using data from previous consents
          ConsentStore.reset();
        })
        .catch(error => {
          if (error.response?.status === 404) {
            setNotFound(true);
            setLoading(false);
          }
        });
    }
  }, []);

  if (id == null || token == null) return <NotFound />;
  else if (loading) return <Loading />;
  else if (expired) return <Expired />;
  else if (notFound) return <NotFound />;
  // if it's an energy bill we don't want to send it to the completed screen
  // since each flow should handle what should happen with each evidence
  else if (consent?.evidence_document_metadata?.evidence_type === ConsentEvidenceType.ENERGY_BILL) {
    switch (consent?.evidence_document_metadata?.review_status) {
      case ConsentEvidenceStatus.PENDING:
      case ConsentEvidenceStatus.REJECTED:
        return <>{children}</>;
      case ConsentEvidenceStatus.AUTOMATIC_APPROVED:
      case ConsentEvidenceStatus.MANUAL_APPROVED:
        return <ConsentAlreadyCompleted consent={consent} />;
    }
  } else if (consent?.consented_at != null) {
    return <ConsentAlreadyCompleted consent={consent} />;
  }

  return <>{children}</>;
}

export default observer(ConsentFlowGuard);
