import { observer } from 'mobx-react-lite';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../api/API.ts';
import AppContext from '../../../store/AppContext.ts';
import { PostcoderSearchResult } from '../../../types/common.types.ts';
import { ConsentFlowType, HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import navigateToCallback from '../../../utils/navigateToCallback.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import { AddressBox } from '../../atoms/AddressBox/AddressBox.tsx';
import Button from '../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import Card from '../../molecules/Card/Card.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import ConsentSteps, { ConsentStep } from '../../molecules/ConsentSteps/ConsentSteps.tsx';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import DocusealSignatureCapture from '../../organisms/DocusealSignatureCapture/DocusealSignatureCapture.tsx';
import EANSearch, { EANDataAPIMeteringPoint } from '../../organisms/EANSearch/EANSearch.tsx';
import ConsentDetailsCollector from './flow-specific/ConsentDetailsCollector/ConsentDetailsCollector.tsx';
import css from './nl.residential.specific.module.scss';

enum ConsentFlowScreens {
  START = 'start',
  EAN_COLLECTION = 'ean-collection',
  EAN_COLLECTION_ERROR = 'ean-collection-error',
  CONSENT_DETAILS_COLLECTION = 'consent-details-collection',
  CONSENT_SIGN_OFF = 'consent-sign-off',
  COMPLETED = 'completed',
}

function NlResidentialSpecific(): ReactElement {
  const { t } = useTranslation('nl-flow');
  const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.START);
  const [selectedAddress, setSelectedAddress] = useState<PostcoderSearchResult | null>(null);
  const [meteringPoints, setMeteringPoints] = useState<null | EANDataAPIMeteringPoint[]>(null);
  const { LanguageStore, ConsentStore } = useContext(AppContext);

  const Steps: ConsentStep[] = [
    { text: t('common.step1'), value: 'get-ean' },
    { text: t('common.step2'), value: 'provide-consent' },
  ];

  useEffect(() => {
    LanguageStore.setAvailableLanguages([Languages.NL, Languages.EN]);
    LanguageStore.setDefaultLanguage(Languages.NL);
  }, []);

  function handleGenerateDocuseal(): void {
    setScreen(ConsentFlowScreens.CONSENT_SIGN_OFF);
  }

  async function handleSignatureCaptured(): Promise<void> {
    const consent = ConsentStore.consent;
    if (consent) {
      await API.approveConsent(consent.id);
      setScreen(ConsentFlowScreens.COMPLETED);
    }
  }

  function renderScreen(): ReactElement | null {
    const consent = ConsentStore.consent as HostedConsentModel<any, never>;
    if (consent) {
      switch (screen) {
        case ConsentFlowScreens.START:
          return (
            <StartScreen
              onNext={() => {
                setScreen(ConsentFlowScreens.EAN_COLLECTION);
              }}
              partner={consent.account.name}
              logo={consent.account.logo}
              logoMetadata={consent.account.logo_metadata ?? null}
            />
          );
        case ConsentFlowScreens.EAN_COLLECTION:
          return (
            <Card account={consent.account}>
              <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.MD}>
                <ConsentSteps steps={Steps} current={Steps[0]} />
                <Heading>{t('residentialFlow.getEan.heading')}</Heading>
                <EANSearch
                  consent={consent}
                  onNext={(meteringPoints, selectedAddress) => {
                    setSelectedAddress(selectedAddress);
                    setMeteringPoints(meteringPoints);
                    if (meteringPoints === null || meteringPoints.length === 0) {
                      setScreen(ConsentFlowScreens.EAN_COLLECTION_ERROR);
                    } else {
                      setScreen(ConsentFlowScreens.CONSENT_DETAILS_COLLECTION);
                    }
                  }}
                />
              </Flex>
            </Card>
          );
        case ConsentFlowScreens.EAN_COLLECTION_ERROR:
          return (
            <Card account={consent.account}>
              <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.MD}>
                <ConsentSteps steps={Steps} current={Steps[0]} />
                <Heading>{t('nl-flow:searchForEanStep.notFoundHeading')}</Heading>
                {selectedAddress && <AddressBox address={selectedAddress.summaryline} />}
                <Flex gap={Gap.MD}>
                  <Button
                    className={css.fullWidth}
                    onClick={() => {
                      navigateToCallback(consent);
                    }}>
                    {t('common:returnToApp', { account: consent.account.name })}
                  </Button>
                  <Button
                    outline
                    onClick={() => {
                      setScreen(ConsentFlowScreens.EAN_COLLECTION);
                    }}>
                    {t('common:changeAddress')}
                  </Button>
                </Flex>
              </Flex>
            </Card>
          );
        case ConsentFlowScreens.CONSENT_DETAILS_COLLECTION:
          if (!meteringPoints) {
            return null;
          }
          return (
            <Card account={consent.account}>
              <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.MD}>
                <ConsentSteps steps={Steps} current={Steps[1]} />
                <Heading>{t('residentialFlow.provideConsent.detailsCollectionHeader')}</Heading>
                <ConsentDetailsCollector
                  consent={consent}
                  onSaved={handleGenerateDocuseal}
                  onBack={() => {
                    setScreen(ConsentFlowScreens.EAN_COLLECTION);
                  }}
                  address={selectedAddress}
                  meteringPoints={meteringPoints}
                />
              </Flex>
            </Card>
          );
        case ConsentFlowScreens.CONSENT_SIGN_OFF:
          return (
            <Card account={consent.account} unbound>
              <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.MD}>
                <DocusealSignatureCapture
                  flow={ConsentFlowType.NL_RESIDENTIAL_SPECIFIC}
                  onCaptured={handleSignatureCaptured}
                />
              </Flex>
            </Card>
          );
        case ConsentFlowScreens.COMPLETED:
          return <Completed consent={consent} />;
        default:
          return null;
      }
    }
    return null;
  }

  return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(NlResidentialSpecific);
